<template>
  <fragment>
    <header class="action-container">
      <v-row>
        <v-col cols="12" md="6">
          <wx-text-field
            v-model="search"
            :label="$t('common.search')"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            clearable
          />
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-start justify-md-end">
          <measures-export-dialog measure-type="puevents" v-if="isWorximityAdmin" />
          <measures-export-dialog measure-type="giveawaysamples" v-if="isGiveawaySamplingEnabled && isWorximityAdmin" />
        </v-col>
      </v-row>
    </header>

    <wx-data-table
      :headers="columns"
      :items="measureProviders"
      :items-per-page="measureSourcesPerPage"
      :search="search"
      :filterable="true"
      :sort-by="['device', 'dataSource', 'measureType', 'lastReceptionDateTime', 'lastMeasureDateTime']"
      :sort-desc="[false, false, false, true, true]"
      :no-results-text="this.$t('common.noMatchingRecords')"
      :hide-default-footer="measureProviders.length <= measureSourcesPerPage"
      :footer-props="{
        itemsPerPageText: this.$t('productionUnit.measureSources.itemsPerPage'),
        itemsPerPageAllText: this.$t('common.listing.showAllOption'),
      }"
      mobile-breakpoint="sm"
      class="wx-panel"
    >
      <template #[`item.device`]="{ item }">
        <span class="wx-typo-sm ml-2">{{ item.device }}</span>
        <span class="wx-typo-sm font-weight-light ml-2">({{ item.deviceType }})</span>
        <span class="wx-typo-sm font-weight-light ml-4">{{ item.deviceDescription }}</span>
      </template>
      <template #[`item.lastReceptionDateTime`]="{ item }">
        <span :class="getDateTimeCssClasses(item.lastReceptionTimestamp)">{{ item.lastReceptionDateTime }}</span>
      </template>
      <template #[`item.lastMeasureDateTime`]="{ item }">
        <span :class="getDateTimeCssClasses(item.lastMeasureTimestamp)">{{ item.lastMeasureDateTime }}</span>
      </template>
      <template #no-data>
        {{ $t("productionUnit.measureSources.noData") }}
      </template>
    </wx-data-table>

    <v-row>
      <v-col cols="12" class="mt-4">
        <wx-btn-standard color="primary" class="my-3" @click="toProductionUnits()">
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          {{ $t("common.back") }}
        </wx-btn-standard>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ErrorHandling from "@/components/ErrorHandling";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import WxDataTable from "@/components/ui/WxDataTable.vue";
import WxTextField from "@/components/ui/WxTextField.vue";
import { DateTime } from "luxon";
import * as TimeUtils from "@/store/TimeUtils";
import { DATE_TIME_FORMAT } from "@/store/TimeUtils";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import RouteService from "@/router/RouteService";
import MeasuresExportDialog from "@/components/productionunit/MeasuresExportDialog";

export default {
  name: "ProductionUnitMeasurementActivity",
  components: {
    MeasuresExportDialog,
    WxBtnStandard,
    WxDataTable,
    WxTextField,
  },
  props: {
    productionUnitId: {
      type: String,
      required: true,
    },
    isGiveawaySamplingEnabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      measurementActivity: null,
      measureSourcesPerPage: 100,
      refreshLoop: null,
    };
  },
  watch: {
    activeFactory() {
      this.search = "";
      this.productionUnits = [];
      this.loadProductionUnitMeasurementSources();
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    ...mapGetters("user", ["isWorximityAdmin"]),
    columns() {
      return [
        {
          text: this.$t("productionUnit.measureSources.columns.deviceWithType"),
          value: "device",
        },
        {
          text: this.$t("productionUnit.measureSources.columns.dataSource"),
          class: "col-datasource",
          value: "dataSource",
        },
        {
          text: this.$t("productionUnit.measureSources.columns.lastReceptionTime"),
          class: "col-last-reception-time",
          value: "lastReceptionDateTime",
        },
        {
          text: this.$t("productionUnit.measureSources.columns.lastMeasureTimestamp"),
          class: "col-last-timestamp",
          value: "lastMeasureDateTime",
        },
        {
          text: this.$t("productionUnit.measureSources.columns.lastMeasureValue"),
          class: "col-measure-value",
          value: "lastMeasureValue",
        },
      ];
    },
    measureProviders() {
      let list = [];
      if (this.activeFactory && this.measurementActivity) {
        this.measurementActivity.device_measures.forEach((measureProvider) => {
          measureProvider.data_source_measures.forEach((ds) => {
            list.push({
              device: measureProvider.device_id,
              deviceType: measureProvider.device_type,
              deviceDescription: measureProvider.description,
              dataSource: ds.id,
              lastReceptionTimestamp: ds.last_reception_time,
              lastReceptionDateTime: this.formatTime(ds.last_reception_time, this.activeFactory.timezone),
              lastMeasureTimestamp: ds.last_reception_time,
              lastMeasureDateTime: this.formatTime(ds.last_measure_timestamp, this.activeFactory.timezone),
              lastMeasureValue:
                ds.last_measure_value !== null && ds.last_measure_value !== undefined ? ds.last_measure_value : "-",
            });
          });
        });
      }
      return list;
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    toProductionUnits() {
      this.$router.replace(RouteService.toProductionUnits());
    },
    formatTime(timeMillis, timezone) {
      if (timeMillis) {
        return TimeUtils.fromEpochMillis(timeMillis, timezone, DATE_TIME_FORMAT);
      } else {
        return "-";
      }
    },
    getDateTimeCssClasses(timestamp) {
      if (timestamp) {
        const diff = DateTime.utc().toMillis() - timestamp;
        if (diff > ProductionUnitService.getTimeWithoutMeasureToleranceMillis()) {
          return "error--text";
        }
        return "success--text";
      }
      return "error--text";
    },
    loadProductionUnitMeasurementSources() {
      ProductionUnitService.getProductionUnitMeasurementActivity(this.productionUnitId)
        .then((httpResponse) => this.handleRetrievalResponse(httpResponse))
        .catch((error) => this.handleRetrievalError(error));
    },
    handleRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.measurementActivity = httpResponse.data;
      }
    },
    handleRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
    getRefreshDelay() {
      return 10000; // 10 seconds
    },
  },
  mounted() {
    this.loadProductionUnitMeasurementSources();
    clearInterval(this.refreshLoop);
    this.refreshLoop = setInterval(() => this.loadProductionUnitMeasurementSources(), this.getRefreshDelay());
  },
  beforeDestroy() {
    clearInterval(this.refreshLoop);
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  .v-input {
    max-width: 400px;
  }
}

::v-deep .col-device {
  width: 30%;
}

::v-deep .col-datasource {
  width: 10%;
}

::v-deep .col-last-reception-time {
  width: 25%;
}

::v-deep .col-last-timestamp {
  width: 25%;
}

::v-deep .col-measure-value {
  width: 10%;
}
</style>
