<template>
  <article class="container-fluid">
    <h1 class="main-title text-truncate">
      {{ productionUnitName }}
    </h1>

    <production-unit-measurement-activity :production-unit-id="productionUnitId" :is-giveaway-sampling-enabled="isGiveawaySamplingEnabled" />
  </article>
</template>

<script>
import ProductionUnitMeasurementActivity from "@/components/productionunit/ProductionUnitMeasurementActivity.vue";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import ErrorHandling from "@/components/ErrorHandling";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProductionUnitMeasurementActivityView",
  components: { ProductionUnitMeasurementActivity },
  data() {
    return {
      productionUnitId: null,
      isGiveawaySamplingEnabled: false,
    };
  },
  computed: {
    ...mapGetters("user", ["availableProductionUnits"]),
    productionUnitName() {
      const pu = this.availableProductionUnits.find((pu) => pu.id === this.productionUnitId);
      return pu ? pu.name : this.$t("common.nA");
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    getProductionUnit(productionUnitId) {
      return ProductionUnitService.getProductionUnit(productionUnitId)
        .then((response) => this.handleProductionUnitRetrievalResponse(response))
        .catch((error) => this.handleProductionUnitRetrievalError(error.response));
    },
    handleProductionUnitRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.isGiveawaySamplingEnabled = this.validateGiveawayPackageAndSamplingConfig(httpResponse.data);
      }
    },
    handleProductionUnitRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    validateGiveawayPackageAndSamplingConfig(pu) {
      return pu.packages.includes("SF4") && pu.giveaway_sampling_configuration !== null;
    },
  },
  beforeMount() {
    this.productionUnitId = this.$route.params.id;
    this.getProductionUnit(this.productionUnitId);
  }
};
</script>
